/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/Devi-Premnath-2.png";

function AbstractSpeakers3() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 3"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker3" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Devi Premnath</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Jansons School of Business, India</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: Navigating Change in Management through AI-Driven Marketing</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            In today’s rapidly evolving business environment, the integration of Artificial
Intelligence (AI) in marketing is no longer just a trend—it&#39;s a transformative force reshaping
management practice. This keynote will delve into the crucial role AI plays in navigating
organizational change, with a focus on its application within marketing strategies.
            <br/>
AI technologies such as machine learning, natural language processing, and predictive
analytics are revolutionizing how businesses understand and engage with their customers. By
automating routine tasks, enhancing data-driven decision-making, and enabling personalized
customer interactions at scale, AI empowers marketing teams to achieve unprecedented levels
of efficiency and effectiveness.<br/>
Key topics covered will include AI-driven market Research, Personalization at Scale,
Predictive Analytics, Marketing Automation, Ethical Considerations, and the Challenges of
AI implementation. The address will also showcase real-world case studies of successful AI
applications in marketing, offering attendees practical insights.
By embracing AI, businesses can not only navigate the complexities of change management
but also foster innovation and achieve sustainable growth in the digital age.
</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Dr. Devi Premnath is a distinguished professor and the Head of Academic Collaborations at
Jansons School of Business, Coimbatore. She holds a Ph.D. in Marketing from Bharathiar
University and has been a university rank holder at both the undergraduate and postgraduate
levels, earning numerous accolades throughout her academic journey.<br/>
A passionate educator and researcher with more than 20 years of experience, Dr. Devi plays a
pivotal role in curriculum development as the Coordinator for the Board of Studies and
International Academic Advisory Committee at Jansons School of Business and serves on the
Governing Board of JSB. Her active involvement in national and international academic
collaborations further highlights her commitment to education.<br/>
Dr. Devi’s extensive research contributions focus on marketing, artificial intelligence, and
business strategies, with numerous publications in peer-reviewed journals and case studies
featured in Scopus-listed journals. She is a sought-after resource person for various national
and international conferences and has delivered training sessions on marketing and business
strategies for executives.<br/>
Her certifications include expertise in brand marketing and social media marketing.
Additionally, she is a seasoned event organizer, speaker, and editor of the newsletter “JSB
Melange.” Dr. Devi’s love for various art forms allows her to blend creativity with her
academic pursuits, making her a multifaceted academician dedicated to shaping the future of
management education.
        </a>
        </Figure>
        </Row>
        
        {/* <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Multivariate Data Analysis (MDA), increasingly referred to as Data Analytics, is evolving rapidly, and changing the world we live in. 
        This trend will accelerate rapidly in the next few years as artificial intelligence disrupts everything humans have become accustomed 
        to in previous centuries. Three primary factors are contributing to these emerging trends: (1) increasing availability of data, a 
        phenomenon referred to as “Big Data”; (2) computing capability (hardware &amp; software) that facilitates efficient analysis of the 
        data; and (3) the emergence of artificial intelligence (AI) models, such as ChatGPT, Microsoft Copilot, Google Gemini, Amazon 
        CodeWhisperer, Anthropic Claude, Perplexity, ChatSonic, etc. These factors are enhanced by decision models supported by rapid 
        technology developments, and executed with two broad types of decision models: Supervised Learning (traditional models with IVs and 
        DVs = labelled data; e.g., PLS-SEM) and Unsupervised Learning (emerging models to identify unlabelled data patterns; e.g., machine 
        learning). Buckle your seatbelt and be prepared to adopt continuous learning strategies. You do not want to be left behind!!</a>
        
        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography <br/>Joseph F. Hair, Jr., Ph.D.</b></a>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            Dr. Hair is Director, Ph.D. Program in Business, and Cleverdon Endowed Chair of Business, Mitchell College of Business, University 
        of South Alabama, U.S.A. Joe is ranked #1 globally in Multivariate Data Analysis, PLS-SEM, and Marketing (Google Scholar, November 
        2024). His career citations exceed 507,000 and his H-Index and I-10 index are 133 and 561, respectively. Joe has published 140+ 
        editions of his books, including
Multivariate Data Analysis, Cengage Learning, U.K., 8 th edition 2019; Essentials of Business
Research Methods, Routledge, 5 th edition 2023; A Primer on Partial Least Squares Structural
Equation Modeling, Sage, 3 rd edition, 2022; A Primer on Partial Least Squares Structural
Equation Modeling: R Version, Springer, 2022; Marketing Research, McGraw-Hill, 6 th edition,
2023, Essentials of Marketing Analytics, McGraw-Hill, 2 nd edition 2023, and Analytics Based
Selling, Sage, forthcoming 2025. He has also published 180+ articles in scholarly journals
such as the Journal of Academy of Marketing Science, Journal of Marketing Research,
Organizational Research Methods, Harvard Business Review, European Journal of

Marketing, Journal of Family Business Strategy, European Management Journal, and
numerous others.</a>

<a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
He is a Distinguished Fellow of the Academy of Marketing Science, the Society for
Marketing Advances, and Southwestern Marketing Association. He has been retained as a
consultant for numerous companies in a variety of industries, as well as by the U.S.
Department of Agriculture and the U.S. Department of Interior. He also has provided expert
testimony, most often in the areas of marketing, advertising and promotions,
entrepreneurship, and statistical and economic analysis, and has planned/presented
executive development and management training programs. Finally, he is regularly invited to
deliver keynote presentations on research techniques, business analytics, and marketing
issues for organizations in the U.S., Europe, Asia, Australia, Africa, South America and
selected other countries.
        </a> */}
    
     
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers3;
