/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker1 from "assets/images/keynote-speakers/sahol-hamid.jpg";

function AbstractSpeakers1() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 1"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker1" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
      <Row >
        {/* <Col xs={6} md={4}> */}
            {/* <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker1} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem"}}>Professor Emeritus Tan Sri Dato&apos; Sri Ir Dr Sahol Hamid Bin Abu Bakar</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem"}}>
                        Vice-Chancellor, UNITAR International University, Malaysia
                    </Card.Text>
                   
                   
                </Card.Body>
            </Card> */}

            {/* <Image src={speaker1} thumbnail fluid  /> */}

            {/* <Figure>
                <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src={speaker1}
                />
                <Figure.Caption>
                    <b><a style={{fontSize: "1rem"}}>Professor Emeritus Tan Sri Dato&apos; Sri Ir Dr Sahol Hamid Bin Abu Bakar</a></b> <br/>
                    Vice-Chancellor, UNITAR International University, Malaysia
                </Figure.Caption>
            </Figure> */}
        {/* </Col>  */}
        <Figure>
          <Figure.Image
          width={171}
          height={180}
          alt="171x180"
          src={speaker1}
          />
          <Figure.Caption>
            <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Emeritus Tan Sri Dato&apos; Sri Ir. Dr. Sahol Hamid Bin Abu Bakar</a></b> <br/>
            <a style={{fontSize: "0.9rem", color:"black"}}>Vice-Chancellor, UNITAR International University, Malaysia</a>
          </Figure.Caption>
          
          <br/>
          
          <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
            <b>Keynote Title: Leadership In Organisations: Driving Performance Through Trust, Transparency and Emotional Intelligence</b>
          </a>
          
          <br/><br/>
          
          <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
            <b>Abstract</b>
          </a>
          
          <br/>

          <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
          Leadership plays a crucial role in driving organisational performance. This entails employee motivation, unleashing the corporate spirit, productivity, competitiveness and sustainability. The keynote will discuss concepts of leadership and how exemplary leadership, and organisational performance is underpinned by skills and attributes of trust, transparency and emotional intelligence.
          </a>

          <br/> <br/>
          
          <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
          
          <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
          Professor Sahol Hamid bin Abu Bakar is a distinguished academician and administrator with over four decades of experience in teaching, research, engineering consultancy, 
          leadership, and governance. He holds a B.Eng. (Hons) in Civil Engineering from UiTM, Malaysia, and dual Master’s Degrees MSc. in Civil Engineering and MSc. Economics from 
          Colorado State University, USA.<br/>
          
          In 1992, he obtained his D.Phil. in Civil Engineering from the University of Sussex, UK. He has been honoured with two honorary doctorates from the University of 
          Sussex, UK, and the University of Stuttgart, Germany, being the first Asian to receive the Honorary Doctorate Dr. Ing from the University of Stuttgart.<br/>
          
          Prof. Sahol Hamid Bin Abu Bakar commenced his career as a lecturer in 1976 and subsequently ascended the administrative hierarchy to assume the role of Vice Chancellor 
          of Universiti Teknologi MARA, Malaysia (2010 - 2016).<br/>
          
          Following his tenure at UiTM, he became the Vice Chancellor of B. S. Abdur Rahman Crescent Institute of Science and Technology, Chennai, India, thereby becoming 
          the first foreign Vice Chancellor of an Indian University. He also served as a consultant to two universities in Ankara, Turkey.<br/>
          
          His expertise encompasses Leadership, Emotional Intelligence, Organizational Intelligence, and Blue Ocean Strategy, which he has successfully implemented in the 
          universities he has led. Prof. Sahol has also provided training to numerous potential Vice Chancellors in India (in collaboration with Aligarh Muslim University) and 
          has extended his knowledge in management and administrative acumen to train other Vice Chancellors and senior administrators of various higher educational institutions 
          in leadership skills worldwide.<br/>
          
          He has been a frequent invitee to Oxford, UK, to train potential VCs and VCs from various countries globally. His notable expertise includes transforming universities 
          into entrepreneurial entities.<br/>
          
          Subsequent to his term in India, Prof. Sahol returned to Malaysia in October 2019 and currently holds the position of Vice Chancellor at UNITAR International University 
          in Malaysia. He continues to contribute to both private and public universities.<br/>
          
          Prof. Sahol is a recipient of numerous awards and honours, including the highly esteemed titles of Tan&lsquo; Sri and Dato&lsquo; Sri in Malaysia. He is also a Fellow 
          of the Academy of Sciences, Malaysia, and the Institution of Engineers, Malaysia. He is also a registered Professional Engineer.

          </a>

          </Figure>
      </Row>
    
     
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers1;
