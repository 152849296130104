/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/jess-low.jpg";

function AbstractSpeakers11() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 11"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker11" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Jess Low</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Job Majestic</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: How AI is Influencing Today’s Talent Landscape</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            In early 2024, the rise of AI is reshaping the job market, bringing both excitement and
uncertainty. ChatGPT and similar technologies are seen as a double-edged sword: while
some view them as a doorway to new opportunities, others fear they might lead to job
losses and make human skills seem outdated. The risk of layoffs adds to the worry that
machines could replace certain human roles.<br/>
To thrive in this evolving landscape, businesses are encouraged to increase capital
expenditures (CAPEX) and reduce operational expenses (OPEX) to enhance long-term
financial stability, lower costs, and attract top talent.<br/>
Yet there’s a disconnect: while 89% of employers rank AI skills as a priority, 81% struggle to
find candidates with the right knowledge. Many employees remain unsure of which skills are
essential or where to begin learning.<br/>
AI literacy spans a wide range of skills, from mastering tools and visualizing data to managing
projects and building personal brands. With these skills come new roles—AI project
managers, data scientists, natural language processing (NLP) specialists, and prompt
engineers—each contributing to the complex puzzle that companies must piece together in
an evolving digital landscape.<br/>
Businesses are advised to assess their digital readiness—whether traditional, hybrid, or fully
digital—and seek talent that aligns with their growth stage. By fostering in-house skills,
adopting flexible workforce strategies, and refining recruitment practices, companies can
build resilience in a rapidly advancing technological world.
</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Jess Low is a seasoned serial entrepreneur with over a decade of experience in the people
industry. As the Founder and CEO of Job Majestic, a prominent job portal, and JEV
Management, a full-fledged licensed recruitment agency, Jess has built a vast network, with
over 8,000 employers and 100,000 job seekers in the agency’s database.<br/>
In addition to entrepreneurship, Jess is a respected media personality, serving as a video
host on CityPlus, Malaysia’s leading Chinese business radio station, and contributing content
to esteemed publications like HR Asia and The Entrepreneur. Known for a passion for
fostering career success, Jess has delivered talks at 18 universities across Malaysia, including
UNITAR, KLMUC, UTAR, HELP, UKM, and UM, supporting students in their professional
development.<br/>
Recognized as a thought leader, Jess frequently appears as a panel speaker at major industry
events, such as AFFIN Bank 2024, Retail Solution Expo (RESONEX 2023), Malaysia Business
Expo 2022, MRCA Franchise Development Manpower Planning, Signature Market, and
Persatuan Usahawan Maju Malaysia (PUMM). Jess&#39;s media presence spans platforms like
BFM, Astro, CityPlus, and 8FM, where insights on recruitment and talent development are
shared with a wider audience.<br/>
Certified as an HRDF trainer and an expert in multilingual talent recruitment, Jess remains a
driving force in Malaysia’s dynamic job market, empowering organizations and individuals to
reach their full potential.
        </a>
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers11;
