// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/jo-ann.jpg";

function AbstractSpeakers4() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 4"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker4" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
              width={171}
              height={180}
              alt="171x180"
              src={speaker}
            />
            <Figure.Caption>
              <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Jo Ann Ho</a></b> <br/>
              <a style={{fontSize: "0.9rem", color:"black"}}>Universiti Putra Malaysia, Malaysia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
              <b>Keynote Title: Driving Organizational Sustainability: From Pro-Environmental Behavior to Extraordinary Change</b>
            </a>

            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
              <b>Abstract</b>
            </a>
            <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              In today&rsquo;s rapidly evolving business landscape, sustainability is no longer a peripheral concern but a central tenet of organizational success. 
              This keynote, &quot;Driving Organizational Sustainability: From Pro-Environmental Behavior to Extraordinary Change,&quot; explores the transformative potential 
              of empowering employees to lead sustainability efforts within organizations. Beginning with Pro-Environmental Behavior (PEB) which refers to routine actions like 
              recycling and energy conservation, this talk emphasizes how organizations can move beyond basic tasks to cultivate Extraordinary Pro-Environmental Behavior (E-PEB), 
              where employees voluntarily innovate and take ownership of green initiatives. By leveraging Green Human Resource Management (GHRM) practices, such as green 
              recruitment, training and performance management, organizations can foster a green identity that deeply engages employees. Steps to foster E-PEB including encouraging 
              emotional engagement, providing autonomy, aligning values and offering recognition will be highlighted as actionable strategies to drive meaningful change. This 
              presentation calls on leaders to create a culture where sustainability is a passion, not just an obligation, empowering employees to become environmental advocates 
              and innovators within their organizations.
            </a>
            
            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a><br/>
             
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              Professor Jo Ann Ho is a distinguished academic at the School of Business and Economics, Universiti Putra Malaysia, specializing in business ethics and corporate 
              social responsibility. She completed her PhD in Business Studies at Cardiff Business School, Cardiff University. Over her career, she has held pivotal roles, 
              including Program Head for the Bachelor of Business Administration with Honors and Assistant Dean of Internationalization. Jo Ann has received prestigious research 
              grants, including from the Sumitomo Foundation, Malaysia’s Ministry of Higher Education and the Ministry of Science, Technology and Innovation (MOSTI), Malaysia.<br/>
              
              Her scholarly contributions encompass numerous journal articles, book chapters and co-authored books, such as “Strategic Management”, “Ph.D. Anyone?” and “Successful 
              Ph.D. Anyone?”. She has also consulted on projects for organizations such as PETRONAS and the Asian Institute of Finance. Additionally, Jo Ann serves on editorial 
              boards for several journals, including the Journal of Hospitality and Tourism Research, Business Ethics, the Environment and Responsibility, International Journal 
              of Economics &amp; Management, Asian Journal of Case Research and Social Responsibility Journal. Jo Ann frequently shares her expertise as a speaker at international 
              conferences and academic forums, engaging a global audience with her insights.
            </a>
        </Figure>
        </Row>     
    </Container> 
    </BaseLayout>
  );
}

export default AbstractSpeakers4;
