/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Images
import speaker1 from "assets/images/keynote-speakers/sahol-hamid.jpg";
import speaker2 from "assets/images/keynote-speakers/joseph-hair.jpg";
import speaker3 from "assets/images/keynote-speakers/Devi-Premnath.jpg";
import speaker4 from "assets/images/keynote-speakers/jo-ann.jpg";
import speaker5 from "assets/images/keynote-speakers/piyush-sharma.jpg";
import speaker6 from "assets/images/keynote-speakers/nurul-indarti.jpg";
import speaker7 from "assets/images/keynote-speakers/norlia-ahmad.jpg";
import speaker8 from "assets/images/keynote-speakers/jasmine-begum.jpg";
import speaker9 from "assets/images/Hajah-Mahuran-Saro-Dato-Haji-Sariki.png";
import speaker10 from "assets/images/keynote-speakers/prof-yolanda.jpg";
import speaker11 from "assets/images/keynote-speakers/jess-low.jpg";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

import "App.css";

// prettier-ignore
const theme = createTheme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: '#f44336',
      },
    },
  });

function KeynoteSpeakers() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Keynote Speakers"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeakers" },
        { label: "KeynoteSpeakers" },
      ]}
    >

    <Container>
      <Row>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker1} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Emeritus Tan Sri Dato&apos; Sri Ir. Dr. Sahol Hamid Bin Abu Bakar</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                        Vice-Chancellor, UNITAR International University, Malaysia
                    </Card.Text>
                    {/* <Button variant="primary">Abstract</Button> */}
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-sahol-hamid-abu-bakar">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                    {/* <div className="btnSubmitPaper">
                        <Stack spacing={2} direction="row">
                            <a href="../elements/abstracts/abstract-sahol-hamid-abu-bakar">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>SUBMIT YOUR PAPER</Button>
                            </a>
                        </Stack>
                    </div>  */}
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>

            </Card>
        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker2} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Joseph F. Hair</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                        University of South Alabama, USA
                    </Card.Text>
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-joseph-f-hair">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>

        </Col>
      
      </Row>
    
      <Row>
      <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker3} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Devi Premnath</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Jansons School of Business, India
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-devi-premnath">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker4} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Jo Ann Ho</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Universiti Putra Malaysia, Malaysia
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-jo-ann-ho">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>

        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker5} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Piyush Sharma</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Curtin University, Australia
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-piyush-sharma">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </Col>
        
      </Row>
      <Row>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker6} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Nurul Indarti</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Universitas Gadjah Mada, Indonesia
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-nurul-indarti">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </Col>

        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker7} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Norlia Ahmad</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Kwansei Gakuin University, Japan
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-norlia-ahmad">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </Col>

        <Col sm>
            <Card style={{ width: '16rem' }}>
            <Card.Img variant="top" src= {speaker10} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Yolanda Masnita Siagian</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                        Universitas Trisakti, Indonesia
                    </Card.Text>
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-yolanda-masnita-siagian">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </Col>

      </Row>

      <Row>
      <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker8} />
                <Card.Body>
                <Card.Title style={{fontSize: "1rem", color:"black"}}>Hon. Prof. Dr. Jasmine Begum</Card.Title>
                <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                    Microsoft, Malaysia
                </Card.Text>
                <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-jasmine-begum">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>
        </Col>
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker9} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem", color:"black"}}>Hjh Mahuran Saro Dato’ Haji Sariki</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                        TalentCorp, Malaysia
                    </Card.Text>
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-mahuran-saro-dato-haji-sariki">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>

        </Col>   
        <Col sm>
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src= {speaker11} />
                <Card.Body>
                    <Card.Title style={{fontSize: "1rem", color:"black"}}>Jess Low</Card.Title>
                    <Card.Text style={{fontSize: "0.9rem", color:"black"}}>
                        Job Majestic, Malaysia
                    </Card.Text>
                    <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                            <a href="../elements/abstracts/abstract-jess-low">
                                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>ABSTRACT</Button>
                            </a>
                        </Stack>
                    </ThemeProvider>
                </Card.Body>
            </Card>

        </Col>
      </Row>
    </Container>

    
        
    
    </BaseLayout>
  );
}

export default KeynoteSpeakers;
