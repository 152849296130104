/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";

import MKTypography from "components/MKTypography";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";

function OrganizingCommittee() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Organizing Committee"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/OrganizingCommittee" },
        { label: "Organizing Committee" },
      ]}
    >

      <MKBox component="section" py={3}>
        <Container>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Patron
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Professor Emeritus Tan Sri Dato&apos; Sri Ir. Dr. Sahol Hamid Bin Abu Bakar,</b><br/>
                sahol.hamid@unitar.my<br/>
                <i>Vice-Chancellor, UNITAR International University, Malaysia</i><br/>
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Conference Advisor
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Prof. Dr. Yap Bee Wah,</b><br/>              
                    bee.wah@unitar.my<br/>   
                <i>UNITAR International University, Malaysia</i><br/>    
                <b>- Dr. Liew Cheng Siang,</b><br/>
                    liew.chengsiang@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i><br/>            
              
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Conference Chairs
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Prof. Dr. Rosmimah Binti Mohd Roslin,</b><br/>  
                rosmimah@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i><br/>                                                        
                <b>- Dr. Abdullah Abdulaziz Abdullah Bawazir (Co- Chair),</b><br/>   
                abdullahbawazir.pt@unitar.my<br/>                   
                <i>UNITAR International University, Malaysia</i><br/>                            
                <b>- Dr. Zam Zuriyati Mohamad (Co-Chair),</b><br/>
                zuriyati@utar.edu.my<br/>  
                <i>Universiti Tunku Abdul Rahman, Malaysia</i>           
            </Box>

            <br/>         

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                International Scientific Committee
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Prof. Dr. Yolanda Masnita Siagian,</b><br/> 
                yolandamasnita@trisakti.ac.id<br/>
                <i>Trisakti University, Indonesia</i><br/>              
                <b>- Prof. Dr. Asep Hermawan,</b><br/> 
                azep@fe.trisakti.ac.id<br/>
                <i>Trisakti University, Indonesia</i><br/>              
                <b>- Assoc. Prof. Dr. Sasivimol Meeampol,</b><br/>
                fbussas@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i>                
            </Box>   

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Proceeding Editors
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Prof. Dr. Rosmimah Binti Mohd Roslin,</b><br/> 
                rosmimah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i><br/>             
                <b>- Prof. Dr. Norlia Ahmad,</b><br/>
                norlia83@kwansei.ac.jp<br/>
                <i>Kwansei Gakuin University, Japan</i><br/>             
                <b>- Prof. Dr. Nurul Indarti,</b><br/>
                nurulindarti@ugm.ac.id<br/>   
                <i>Gadjah Mada University, Indonesia</i>           
            </Box>     

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Secretarariat
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Ms. Aimi Aqilah Binti Samsuri,</b><br/> 
                aimi.samsuri@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>             
                <b>- Ms. Simranpreet Kaur Hansaram,</b><br/>
                rammilah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>                      
                <b>- Ms. Nurmunirah Binti Azami,</b><br/> 
                nurmunirah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>                       
                <b>- Ms. Dalmie Shahrul Suryati Binti Azmee,</b><br/> 
                dalmie.pt@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i>                    
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Finance and Registration
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Mr. Chong Kim Loy,</b><br/>
                chongkl@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>         
                <b>- Mr. Quat Soo Huat,</b><br/>
                quat.soohuat@unitar.my
                <i>UNITAR International University, Malaysia</i> <br/>         
                <b>- Mr. Djasriza Binti Jasin,</b><br/>
                djasriza@unitar.my
                <i>UNITAR International University, Malaysia</i> <br/>                                      
                <b>- Ms. Wang Wan Yong,</b><br/>
                wang.wanyong@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>      
                <b>- Ms. Khairatun Hisan Binti Idris Shazali,</b><br/>
                khairatun@unitar.my<br/>    
                <i>UNITAR International University, Malaysia</i>                      
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Technical
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Dr. Sharareh Shahidi Hamedani (Chair),</b><br/>
                sharareh.pt@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Assoc. Prof. Dr. Farhad Nadi,</b><br/>
                farhad.nadi@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Sharfika Binti Raime,</b><br/>
                sharfika.pt@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Assoc. Prof. Dr. Rudzi Binti Munap,</b><br/>
                rudzi@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Aervina Binti Misron,</b><br/>
                aervina.misron@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Nurul Afidah Binti Mohamad Yusof,</b><br/>
                afidahj@utar.edu.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Assoc. Prof. Dr. Puteri Aina Binti Megat Ameir Noordin,</b><br/>
                puteriaina@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Ramila Devi A/P Ram Sing @ Lang Sing,</b><br/>
                ramila@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Sarina Binti Mohamad Nor,</b><br/>
                sarina@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Segufta Yasmi Binti Abdul Rahman,</b><br/>
                segufta@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Bha Aldan Mundher Oraibi,</b><br/>
                bha.aldan@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. You Hui Wei,</b><br/>
                huiwei.you@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  

                <b>- Dr. Erna Andajani,</b><br/>
                ernajani@ubaya.ac.id<br/>
                <i>Surabaya University, Indonesia</i><br/>  

                <b>- Dr. Husna Leila Yusran,</b><br/>
                husna.leila@trisakti.ac.id<br/>
                <i>Trisakti University, Indonesia</i><br/>

                <b>- Assoc. Prof. Dr. Polwat Lerskullawat,</b><br/>
                fbuspwl@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Thirarut Worapishet,</b><br/>
                fbusths@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Chuenchit Changchenkit,</b><br/>
                fbuscjc@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Thongchai Srivardhana,</b><br/>
                fbustcs@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Paitoon Chetthamrongchai,</b><br/>
                fbusptc@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Jul Thanasrivanitchai,</b><br/>
                jul.t@ku.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
                <b>- Dr. Pornlapas Suwannarat,</b><br/>
                pornlapas.su@ku.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Program Book
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Ms. Hanita Sarah Binti Saad,</b><br/>
                hanita@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Azrin Binti Abdul Razak,</b><br/>
                azrinrazak@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Zainora Hayat Binti Hudi @ Jahit,</b><br/>
                zainora@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
            </Box>  

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Publicity
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Ms. Munira Binti Saaidin,</b><br/>
                munirah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Normaziah Binti Che Musa,</b><br/>
                normaziah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Aida Binti Shaari,</b><br/>
                aida@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Sallehatul Asfiah Mohamad Salleh,</b><br/>
                asfiah@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Muhammad Syafiq Bin Mohd Salleh,</b><br/>
                msyafiq.pt@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Mohamad Shah Andrew Bin Ibrahim,</b><br/>
                shahandrew@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Marc Kevin Natusch,</b><br/>
                marc.kevin@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Dr. Vikniswari A/P Vija Kumaran,</b><br/>
                vikniswarivk@utar.edu.my<br/>
                <i>Universiti Tunku Abdul Rahman, Malaysia</i><br/>
                <b>- Assoc. Prof. Dr. Haruthai Numprasertchai,</b><br/>
                haruthai.p@ku.ac.th<br/>
                <i>Kasetsart University, Thailand</i><br/>
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Logistic
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Mr. Rashdeen Bin Rashidi ,</b><br/>
                rashdeen.pt@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Santhana Raja Rajamanikam,</b><br/>
                santhana.raja@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Amri Bin Ahmad,</b><br/>
                amri_ahmad@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Syed Munir Barakbah Bin Syed Faozi Barakbah,</b><br/>
                syedmunir@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Nur Nadiah Binti Mahbob,</b><br/>
                nur.nadiah@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Mohd Faisal Bin Mohd Noor,</b><br/>
                faisal@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Muhammad Shahrir Aizat,</b><br/>
                shahrir.shuhaili@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Nurlisnawati Binti Mohd Hijazi,</b><br/>
                nurlisnawati@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
            </Box>

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Sponsorship
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Ms. Yanti Binti Lumat,</b><br/>
                yanti@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Hanifah Binti Moksin,</b><br/>
                hanifahm@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Sarita Binti Ibrahim,</b><br/>
                sarita@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Ms. Rohana Binti Ahmad Shimi,</b><br/>
                rohanas@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Shahrizal Bin Mohd Safri,</b><br/>
                shahrizal.safri@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Hazezul Razman Othman,</b><br/>
                hazezul@unitar.my<br/>  
                <i>UNITAR International University, Malaysia</i> <br/>  
            </Box>   

              <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                IT
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Mr. Misyer Bin Mohamed Tajudin,</b><br/>
                misyer64@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Ikmal Bin Abd Malik,</b><br/>
                ikmal@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Amiruddin Azizi Bin Salleh,</b><br/>
                amiruddin.salleh@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Abdul Aziz Bin Jusoh,</b><br/>
                aaziz.pt@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Ker Boon Chin,</b><br/>
                boonchin.ker@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Mohd Aiman Bin Mohd Sani,</b><br/>
                aiman@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Mr. Ahmad Ruzaini Bin Rahim,</b><br/>
                ruzaini.rahim@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
            </Box>      

            <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Industry
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Mr. Jesus Cuevas Tinawin,</b><br/>
                jesus.tinawin@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>  
                <b>- Prof. Dato&apos; Dr. Jesbil Singh A/L Bahadur Singh,</b><br/>
                jesbil.bahadursingh@unitar.my<br/> 
                <i>UNITAR International University, Malaysia</i> <br/>        
            </Box>                                                  

             <br/>

            <MKTypography variant="button" color="text" fontWeight="bold" textTransform="uppercase">
                Corporate
            </MKTypography>
            <Box component="section" sx={{ p: 2, border: '2px solid grey' }}>
                <b>- Ms. Stella Chua Ching Yee,</b><br/>
                stella.chua@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>        
                <b>- Ms. Sew Soon Tep,</b><br/>
                samantha.sew@unitar.my<br/>
                <i>UNITAR International University, Malaysia</i> <br/>        
            </Box>           

                     

        </Container>
      </MKBox>

    </BaseLayout>
  );
}

export default OrganizingCommittee;
