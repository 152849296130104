/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/norlia-ahmad.jpg";

function AbstractSpeakers7() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 4"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker4" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Norlia Ahmad</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Kwansei Gakuin University, Japan</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: Transforming Marketing Education in the VUCA World: Are We Ready for Innovation?</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            Business schools encourage innovation and positive changes for students and market
practices, yet they often struggle to innovate their curriculum to meet evolving demands and
embrace new educational approaches. This keynote speech seeks to stimulate discussion
and reimagine marketing education in the volatile, uncertain, complex, and ambiguous
(VUCA) world. A holistic approach to marketing education requires innovations that
integrate crucial issues of our time including artificial intelligence (AI), sustainability, and
ethics. As Gen Z students face unique challenges and have specific needs and values, it is
equally important to equip them with “learning about”, “learning to do”, and “learning to
be” skills. How can we effectively bring about meaningful change in pursuit of a more
relevant and holistic approach to marketing education? What does the future of marketing
education and business schools hold? Perhaps the best way to address the latter question is
to actively shape the future ourselves.  <br/>
Keywords: marketing education, artificial intelligence, sustainability, ethics, VUCA world.
</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Norlia Ahmad, PhD, is a Professor of Marketing at the Institute of Business and Accounting,
Kwansei Gakuin University, Japan. Her research focuses on the dynamic relationship
between technology and consumer behaviour. She has a keen interest in understanding the
far-reaching implications of artificial intelligence (AI) on various aspects of human
experience, including education. Her exploration of these topics extends to both her
research and the MBA courses she teaches, where she addresses the intricate interplay
between marketing, technology, and sustainability in today’s disruptive world.
        </a>
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers7;
