import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

import Speaker1 from "layouts/sections/elements/abstracts/abstract-sahol-hamid-abu-bakar";
import Speaker2 from "layouts/sections/elements/abstracts/abstract-joseph-f-hair";
import Speaker3 from "layouts/sections/elements/abstracts/abstract-devi-premnath";
import Speaker4 from "layouts/sections/elements/abstracts/abstract-jo-ann-ho";
import Speaker5 from "layouts/sections/elements/abstracts/abstract-piyush-sharma";
import Speaker6 from "layouts/sections/elements/abstracts/abstract-nurul-indarti";
import Speaker7 from "layouts/sections/elements/abstracts/abstract-norlia-ahmad";
import Speaker8 from "layouts/sections/elements/abstracts/abstract-jasmine-begum";
import Speaker9 from "layouts/sections/elements/abstracts/abstract-mahuran-saro-dato-haji-sariki";
import Speaker10 from "layouts/sections/elements/abstracts/abstract-yolanda-masnita-siagian";
import Speaker11 from "layouts/sections/elements/abstracts/abstract-jess-low";

// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    // prettier-ignore
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="sections/elements/abstracts/abstract-sahol-hamid-abu-bakar" element={<Speaker1/>} />
        <Route path="sections/elements/abstracts/abstract-joseph-f-hair" element={<Speaker2/>} />
        <Route path="sections/elements/abstracts/abstract-devi-premnath" element={<Speaker3/>} />
        <Route path="sections/elements/abstracts/abstract-jo-ann-ho" element={<Speaker4/>} />
        <Route path="sections/elements/abstracts/abstract-piyush-sharma" element={<Speaker5/>} />
        <Route path="sections/elements/abstracts/abstract-nurul-indarti" element={<Speaker6/>} />
        <Route path="sections/elements/abstracts/abstract-norlia-ahmad" element={<Speaker7/>} />
        <Route path="sections/elements/abstracts/abstract-jasmine-begum" element={<Speaker8/>} />
        <Route path="sections/elements/abstracts/abstract-mahuran-saro-dato-haji-sariki" element={<Speaker9/>} />
        <Route path="sections/elements/abstracts/abstract-yolanda-masnita-siagian" element={<Speaker10/>} />
        <Route path="sections/elements/abstracts/abstract-jess-low" element={<Speaker11/>} />
      </Routes>
    </ThemeProvider>
  );
}
