/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";

import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import proceedingInstruction from "assets/documents/Proceeding-Instruction-to-Authors-(Revision-Sept-2024).pdf";
import copyrightAgreement from "assets/documents/Proceeding-Copyright-Agreement.pdf";

function CameraReadySubmission() {
  return (
    // prettier-ignore
    <BaseLayout
      title="Camera Ready Submission"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/CameraReadySubmission" },
        { label: "Camera Ready Submission" },
      ]}
    >

      <MKBox component="section" py={3}>
        <Container>
        <MKTypography variant="body3">
          Click <a href={proceedingInstruction}>HERE</a> to download Proceeding Instruction to Authors.
        </MKTypography>
        <br/> <br/>
        <MKTypography variant="body3">
          Click <a href={copyrightAgreement}>HERE</a> to download Proceeding Copyright Agreement.
        </MKTypography>
        </Container>
      </MKBox>

    </BaseLayout>
  );
}

export default CameraReadySubmission;
