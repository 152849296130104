/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

// @mui material components
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// import MKTypography from "components/MKTypography";

import { CContainer, CRow, CCol, CImage } from "@coreui/react";

// Images
import poster from "assets/images/ICMAM24-V4-POSTER.jpg";

function Poster() {
  return (
    // prettier-ignore
    <BaseLayout
     title="Poster Conference"
     breadcrumb={[
       { label: "Home", route: "/sections/elements/SubmissionGuidelines" },
       { label: "Poster Conference" },
     ]}
   >
    <MKBox component="section" py={3}>
      <Container>
        <CContainer>
            <CRow className="align-items-center">
                {/*
                <CCol ><CImage fluid src={arshadAyubLogo} alt="arshadAyub" /></CCol> */}
                <CCol sm><CImage fluid src={poster} alt="poster" /></CCol>
            </CRow>
        </CContainer>
        
        {/* <img src={unitarLogo} alt="any" />
        <img src={dosmLogo} alt="any3" className='w-36' /> */}
                  
      </Container>
    </MKBox>
    </BaseLayout>
  );
}

export default Poster;
