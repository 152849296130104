/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/prof-yolanda.jpg";

function AbstractSpeakers10() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 10"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker10" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Yolanda Masnita Siagian</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Universitas Trisakti, Indonesia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: Agile Marketing: Adaptive Strategy in the VUCA Era</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            In the contemporary business landscape characterized by volatility, uncertainty, complexity,
and ambiguity (VUCA), traditional marketing strategies often fall short in addressing the rapid
shifts in consumer behavior and market dynamics. This paper explores the concept of agile
marketing as a flexible and adaptive approach that empowers marketing teams to respond
swiftly to these challenges. Agile marketing is rooted in principles derived from agile
methodologies originally developed for software development, emphasizing short iterations,
cross-departmental collaboration, and a focus on tangible outcomes. By operating in brief
sprints, marketing teams can test, evaluate, and refine their strategies based on real-time
data and consumer feedback, rather than relying on static, long-term plans.<br/>
Key techniques such as the Minimum Viable Product (MVP) approach and regular
retrospectives enable teams to launch simplified campaigns and continuously improve their
strategies based on performance evaluations. The paper highlights the benefits of agile
marketing, including rapid response to market changes, enhanced consumer-centricity, and a
culture of innovation that encourages experimentation with lower risks. Case studies of
successful companies like Spotify and IBM illustrate the practical application of agile
marketing principles, showcasing how these organizations have achieved higher consumer
engagement and campaign relevance through agile practices. Ultimately, this paper
advocates for the adoption of agile marketing as a vital strategy for businesses aiming to
thrive in the unpredictable VUCA environment, ensuring they remain aligned with evolving
consumer needs and market trends.
</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Professor Dr. Yolanda Masnita is a distinguished professor of marketing at Trisakti University
and currently serves as the Dean of the Faculty of Economics and Business. With a strong
focus on Marketing Economic and Interactive Perspectives, she has dedicated her research
to exploring cutting-edge areas within marketing, including Islamic Marketing, New Product
Development, Digital Retail, and Online Marketing. Her expertise lies in investigating how
these areas can evolve within both academic and practical contexts, contributing significant
insights to the field.<br/>
Professor Yolanda has authored several key books on research methodology and marketing,
which are widely regarded as essential resources among academics and practitioners. These
publications reflect her commitment to advancing the study and application of marketing

principles in contemporary settings, particularly in the domains of product innovation and
digital marketing in Indonesia.
        </a>
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers10;
