// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/nurul-indarti.jpg";

function AbstractSpeakers4() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 6"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker6" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
              width={171}
              height={180}
              alt="171x180"
              src={speaker}
            />
            <Figure.Caption>
              <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Nurul Indarti</a></b> <br/>
              <a style={{fontSize: "0.9rem", color:"black"}}>Universitas Gadjah Mada, Indonesia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
              <b>Keynote Title: Navigating Change: Embracing Green Innovation in Indonesia’s Batik Industry</b>
            </a>

            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
              <b>Abstract</b>
            </a>
            <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              The batik industry, a cornerstone of Indonesian cultural heritage, is at a transformative crossroads. Traditionally known for its intricate designs and vibrant 
              colours, the industry has long relied on chemical dyes, raising significant environmental concerns. As the global and local push for sustainability intensifies, 
              batik producers are increasingly exploring green innovations, including the adoption of natural dyes and eco-friendly processes.<br/>
              
              In this keynote, I will discuss the motivations and challenges that batik companies face in transitioning to greener production methods. We will explore the key 
              drivers behind this shift, such as rising consumer demand for sustainable products, stricter environmental regulations, and the long-term economic advantages of 
              adopting green practices. At the same time, I will address the barriers—such as higher costs, the need for technical expertise, potential disruptions to traditional 
              aesthetics, and uncertainty over market acceptance—that complicate the journey toward sustainability.<br/>
              
              Drawing from case studies and interviews with industry stakeholders, I will provide insights into how batik producers are navigating the delicate balance between 
              maintaining cultural authenticity and embracing modern sustainability standards. We will also examine the role of policy frameworks, the impact of green branding 
              on market positioning, and strategies to support small and medium-sized batik enterprises in adopting eco-friendly innovations.<br/>
              
              This speech aims to inspire a collaborative effort among industry players, policymakers, and academics to promote a sustainable future for the Indonesian batik 
              industry—one that respects its rich tradition while committing to environmental stewardship.
            </a>
            
            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a><br/>
             
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              Prof. Nurul Indarti is a Full Professor and Head of the Management Department at the Faculty of Economics and Business, Universitas Gadjah Mada (FEB UGM), 
              Yogyakarta, Indonesia. She holds a Bachelor&#39;s degree from FEB UGM, a Siviløkonom (Master&#39;s degree) from the University of Agder, Norway, a Candidata 
              Mercatoria (Master&#39;s degree) from the Norwegian School of Economics and Business in Bergen, Norway, and a Ph.D. from the Faculty of Economics and Business, 
              University of Groningen, the Netherlands.<br/>
              
              Prof. Indarti is an expert in innovation management, entrepreneurship, and strategic management, with a particular focus on SMEs, international business, and 
              sustainability. She has published over 70 articles in reputable international journals and authored several books. At UGM, she plays a pivotal role in advancing 
              academic programs, especially in sustainability education. A dedicated mentor and researcher, she actively supports community development, empowering local businesses 
              and promoting sustainable practices.
            </a>
        </Figure>
        </Row>     
    </Container> 
    </BaseLayout>
  );
}

export default AbstractSpeakers4;
