/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/piyush-sharma.jpg";

function AbstractSpeakers4() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 5"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker5" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Piyush Sharma</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Curtin University, Australia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: Emerging Digital Technologies and Professional Services: Current and Future Research Agenda</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              Despite the growing widespread interest in the emerging digital technologies (e.g., artificial intelligence, blockchain, internet of things, machine learning, 
              robotics, augmented, mixed and virtual reality), most current research focuses on their impact in the consumer services contexts (e.g., education, healthcare, 
              retailing, and transportation). By contrast, there is little research on the impact of these new digital technologies on the professional services despite their 
              important contribution to economic growth and employment around the world and significant differences with consumer services in their decision-making process. 
              A recent special issue of the Journal of Service Theory and Practice special issue addresses this important research gap and extends growing research on the 
              role of emerging digital technologies in professional services context. In this keynote talk, I will share my experience of managing this special issue and the 
              key findings from the eight articles finally selected for publication in this special issue. These articles use diverse conceptual and empirical (qualitative and 
              quantitative) approaches and are classified into three broad themes, a) Diverse theoretical perspectives, b) Challenges in digitalisation process, and c) Practical 
              applications of digitalisation. I will conclude by suggesting an agenda for future research on this topic of growing importance for consumers, marketers, businesses, 
              government agencies, investors and general public.
            </a>
            
            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              Professor Piyush Sharma serves as John Curtin Distinguished Professor at Curtin University in Perth, Australia. He is ranked among the top 0.2% researchers in 
              the world across all fields of research, top 60 business researchers in the global P-rankings based on publications in ABDC journals since 2013 and top 90 business 
              researchers in Research.com ranking based on D-index. He was also recognised as the only Rising Star from marketing area among Australia’s Top 40 researchers 
              across all disciplines in 2019. He currently serves as a member of the prestigious Australian Research Council (ARC) College of Experts (2022-2024) and Associate 
              Editor (Marketing and International Business) for Journal of Business Research. He has also served as Regional Editor (Asia) for Journal of Knowledge Management, 
              Senior Editor (Marketing) for International Journal of Emerging Markets, Co-Editor of Marketing Intelligence & Planning and Associate Editor for Journal of Services 
              Marketing in the past. He is also a member of the editorial boards of top journals, including Journal of International Business Studies, Journal of the Academy of 
              Marketing Science, Journal of Service Research, Industrial Marketing Management and European Journal of Marketing, among others.<br/> 
              
              Professor Sharma is a multi-disciplinary researcher covering services marketing, international business, cross-cultural consumer behaviour, self-regulation, branding 
              and marketing strategy, marketing-finance interface, and emerging digital technologies, among others. He has published 134 articles in top international journals, 
              including Journal of International Business Studies, Journal of the Academy of Marketing Science, Journal of Service Research, International Journal of Research 
              in Marketing, Industrial Marketing Management, European Journal of Marketing (all ABDC-A* ranked), Journal of Business Research, Journal of Service Management, 
              Psychology & Marketing, and other ABDC-A ranked journals. He has presented his work at more than 125 international conferences, delivered more than 60 keynote 
              speeches and invited talks, and conducted dozens of research workshops around the world. He has also earned more than A$1.2 million in research income from diverse 
              funding agencies and he advises many private, public and not-for-profit industry partners in community care, higher education, financial and healthcare services, 
              and international business sectors, in Australia and overseas. He has also successfully supervised and continues to supervise 26 research students so far, besides 
              mentoring many early and mid-career researchers in Australia and overseas.<br/><br/>
              
              You may see his full profile here: 
              <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}} href="https://staffportal.curtin.edu.au/staff/profile/view/Piyush.Sharma/" target="_blank" rel="noreferrer">
                https://staffportal.curtin.edu.au/staff/profile/view/Piyush.Sharma/
              </a>

            </a>
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers4;
