/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/Hajah-Mahuran-Saro-Dato-Haji-Sariki.png";

function AbstractSpeakers9() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 9"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker9" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Hjh Mahuran Saro Dato’ Haji Sariki</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>TalentCorp, Malaysia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: The AI Revolution: Navigating the Hype, Addressing the Necessary Concerns, and Skills Competency</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            The AI revolution is transforming various sectors with immense potential, but it also brings
significant concerns. While AI promises advancements in healthcare, finance, and
transportation, it raises ethical issues like bias and privacy, economic impacts such as job
displacement, and security risks including cyber threats. Balancing the hype with realistic
expectations is crucial, as is addressing current limitations and ensuring responsible
development. Essential skills competency for the AI era includes technical skills like machine
learning and data science, soft skills such as critical thinking and adaptability, and
interdisciplinary knowledge in ethics and law. Looking ahead, AI has the potential to solve
global challenges, but society must prepare for its widespread impact through thoughtful
regulation and continuous learning.
</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Mahuran brings over 25 years of expertise in human resources strategy and labour market
research. Her research interests include jobs in demand, skills, and competency, and
women’s participation in the labour force. Currently, she is focused on labour market trends,
the AI revolution, future AI jobs and skills required by industry, and supporting the
development of national policies and programs to increase and retain women’s participation
in the labour force. She emphasises the importance of collaboration between the private
and public sectors to design a smart and agile labour market.
        </a>
                    
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers9;
