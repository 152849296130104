/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/jasmine-begum.jpg";

function AbstractSpeakers8() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 8"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker8" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Hon. Prof. Dr. Jasmine Begum</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>Microsoft, Malaysia</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
              <b>Keynote Title: Reinventing The Future: Transformative Innovation In The AI Economy</b>
            </a>

            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
              <b>Abstract</b>
            </a>
            <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              The AI economy is transforming industries, redefining operations, and revolutionizing management and marketing. In this era of rapid change, innovation is no longer 
              optional but essential for organizations to thrive. Reinventing the Future: Transformative Innovation in the AI Economy explores how businesses can harness AI to 
              drive sustainable growth, reimagine business models, and build resilience.<br/>
              
              This session will examine key drivers of the AI economy, focusing on how predictive analytics, personalized engagement, and intelligent automation are 
              revolutionizing practices. Real-world case studies will highlight successes, challenges, and lessons learned, offering practical insights into leveraging AI&apos;s 
              transformative potential. Beyond technology, this presentation underscores the need for a strategic mindset, emphasizing shifts in leadership, culture, and ethics 
              to foster innovation. Attendees will gain actionable strategies to align AI initiatives with business goals and embrace human-AI collaboration, positioning their 
              organizations to lead in this new era.
            </a>
            
            <br/><br/>
            
            <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography</b></a><br/>
             
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
              Jasmine leads Microsoft&apos;s Corporate External and Legal Affairs for the ASEAN region, addressing issues at the intersection of technology, policy, and society in 
              ASEAN. With over 30 years of award-winning experience, she brings extensive expertise in legal, policy, regulatory, geostrategy, and communications. Prior to 
              Microsoft, Jasmine held leadership positions at Dell, the UNDP, and the United Nations Executive Office of the Secretary-General in New York.<br/>
              
              She has made significant milestones in her career, including being the first Malaysian appointed to the Internet Governance Forum (IGF) Multistakeholder Advisory 
              Group (MAG) Her remarkable achievements have also earned her prestigious accolades such as the inaugural WIM&apos;s Tan Sri Napsiah Omar Women Leaders Award and the 
              Global Leadership Award for ICT Excellence. These honors recognize her exceptional contributions to advancing policies on accessibility, promoting women in the 
              field of technology and shaping policy and regulatory landscape for frontier technologies. Jasmine is also an advisor to various Digital Economy Agencies in the 
              region, as well as Multilateral and International Organizations.<br/>
              
              Jasmine holds a double degree in Law from the International Islamic University in Kuala Lumpur, pursued post-graduate studies in the UK, and earned a Ph.D. in 
              business and human rights. With admission to practice law in multiple jurisdictions, she is currently an Adjunct Professor in several Universities across the 
              region and has received an Honorary Doctorate for her outstanding leadership and contributions in Public Policy and Governance from UNITAR International University.
            </a>
                    
        </Figure>
        </Row>
       
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers8;
