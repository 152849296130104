/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";

import * as React from "react";
// prettier-ignore
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import Figure from "react-bootstrap/Figure";

// Images
import speaker from "assets/images/keynote-speakers/joseph-hair.jpg";

function AbstractSpeakers2() {
  return (
    // prettier-ignore
    <BaseLayout
    //   title="Keynote Speaker 1"
      breadcrumb={[
        { label: "Home", route: "/sections/elements/KeynoteSpeaker2" },
        { label: "Abstract" },
      ]}
    >

    <Container style={{justifyContent:"center", display:"-ms-flexbox", textAlign:"justify"}}>
        <Row>
        <Figure>
            <Figure.Image
                width={171}
                height={180}
                alt="171x180"
                src={speaker}
            />
            <Figure.Caption>
                <b><a style={{fontSize: "1rem", color:"black"}}>Prof. Dr. Joseph F. Hair</a></b> <br/>
                <a style={{fontSize: "0.9rem", color:"black"}}>University of South Alabama, USA</a>
            </Figure.Caption>

            <br/>

            <a style={{fontSize: "1.2rem", color:"black", textAlign:"left"}}>
                <b>Keynote Title: Big Data, Analytics, AI and the Future</b>
            </a>

            <br/> <br/>
            
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"left"}}>
                <b>Abstract</b>
            </a>
            <br/>
            <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Multivariate Data Analysis (MDA), increasingly referred to as Data Analytics, is evolving rapidly, and changing the world we live in. 
        This trend will accelerate rapidly in the next few years as artificial intelligence disrupts everything humans have become accustomed 
        to in previous centuries. Three primary factors are contributing to these emerging trends: (1) increasing availability of data, a 
        phenomenon referred to as “Big Data”; (2) computing capability (hardware &amp; software) that facilitates efficient analysis of the 
        data; and (3) the emergence of artificial intelligence (AI) models, such as ChatGPT, Microsoft Copilot, Google Gemini, Amazon 
        CodeWhisperer, Anthropic Claude, Perplexity, ChatSonic, etc. These factors are enhanced by decision models supported by rapid 
        technology developments, and executed with two broad types of decision models: Supervised Learning (traditional models with IVs and 
        DVs = labelled data; e.g., PLS-SEM) and Unsupervised Learning (emerging models to identify unlabelled data patterns; e.g., machine 
        learning). Buckle your seatbelt and be prepared to adopt continuous learning strategies. You do not want to be left behind!!</a>

        <br/> <br/>

        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography <br/>Joseph F. Hair, Jr., Ph.D.</b></a> <br/>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            Dr. Hair is Director, Ph.D. Program in Business, and Cleverdon Endowed Chair of Business, Mitchell College of Business, University 
        of South Alabama, U.S.A. Joe is ranked #1 globally in Multivariate Data Analysis, PLS-SEM, and Marketing (Google Scholar, November 
        2024). His career citations exceed 507,000 and his H-Index and I-10 index are 133 and 561, respectively. Joe has published 140+ 
        editions of his books, including
Multivariate Data Analysis, Cengage Learning, U.K., 8 th edition 2019; Essentials of Business
Research Methods, Routledge, 5th edition 2023; A Primer on Partial Least Squares Structural
Equation Modeling, Sage, 3rd edition, 2022; A Primer on Partial Least Squares Structural
Equation Modeling: R Version, Springer, 2022; Marketing Research, McGraw-Hill, 6 th edition,
2023, Essentials of Marketing Analytics, McGraw-Hill, 2 nd edition 2023, and Analytics Based
Selling, Sage, forthcoming 2025. He has also published 180+ articles in scholarly journals
such as the Journal of Academy of Marketing Science, Journal of Marketing Research,
Organizational Research Methods, Harvard Business Review, European Journal of

Marketing, Journal of Family Business Strategy, European Management Journal, and
numerous others.</a> <br/>

<a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
He is a Distinguished Fellow of the Academy of Marketing Science, the Society for
Marketing Advances, and Southwestern Marketing Association. He has been retained as a
consultant for numerous companies in a variety of industries, as well as by the U.S.
Department of Agriculture and the U.S. Department of Interior. He also has provided expert
testimony, most often in the areas of marketing, advertising and promotions,
entrepreneurship, and statistical and economic analysis, and has planned/presented
executive development and management training programs. Finally, he is regularly invited to
deliver keynote presentations on research techniques, business analytics, and marketing
issues for organizations in the U.S., Europe, Asia, Australia, Africa, South America and
selected other countries.
        </a>
        </Figure>
        </Row>
        
        {/* <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
        Multivariate Data Analysis (MDA), increasingly referred to as Data Analytics, is evolving rapidly, and changing the world we live in. 
        This trend will accelerate rapidly in the next few years as artificial intelligence disrupts everything humans have become accustomed 
        to in previous centuries. Three primary factors are contributing to these emerging trends: (1) increasing availability of data, a 
        phenomenon referred to as “Big Data”; (2) computing capability (hardware &amp; software) that facilitates efficient analysis of the 
        data; and (3) the emergence of artificial intelligence (AI) models, such as ChatGPT, Microsoft Copilot, Google Gemini, Amazon 
        CodeWhisperer, Anthropic Claude, Perplexity, ChatSonic, etc. These factors are enhanced by decision models supported by rapid 
        technology developments, and executed with two broad types of decision models: Supervised Learning (traditional models with IVs and 
        DVs = labelled data; e.g., PLS-SEM) and Unsupervised Learning (emerging models to identify unlabelled data patterns; e.g., machine 
        learning). Buckle your seatbelt and be prepared to adopt continuous learning strategies. You do not want to be left behind!!</a>
        
        <a style={{fontSize: "0.9rem", color:"black"}}><b>Biography <br/>Joseph F. Hair, Jr., Ph.D.</b></a>
        
        <a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
            Dr. Hair is Director, Ph.D. Program in Business, and Cleverdon Endowed Chair of Business, Mitchell College of Business, University 
        of South Alabama, U.S.A. Joe is ranked #1 globally in Multivariate Data Analysis, PLS-SEM, and Marketing (Google Scholar, November 
        2024). His career citations exceed 507,000 and his H-Index and I-10 index are 133 and 561, respectively. Joe has published 140+ 
        editions of his books, including
Multivariate Data Analysis, Cengage Learning, U.K., 8 th edition 2019; Essentials of Business
Research Methods, Routledge, 5 th edition 2023; A Primer on Partial Least Squares Structural
Equation Modeling, Sage, 3 rd edition, 2022; A Primer on Partial Least Squares Structural
Equation Modeling: R Version, Springer, 2022; Marketing Research, McGraw-Hill, 6 th edition,
2023, Essentials of Marketing Analytics, McGraw-Hill, 2 nd edition 2023, and Analytics Based
Selling, Sage, forthcoming 2025. He has also published 180+ articles in scholarly journals
such as the Journal of Academy of Marketing Science, Journal of Marketing Research,
Organizational Research Methods, Harvard Business Review, European Journal of

Marketing, Journal of Family Business Strategy, European Management Journal, and
numerous others.</a>

<a style={{fontSize: "0.9rem", color:"black", textAlign:"justify"}}>
He is a Distinguished Fellow of the Academy of Marketing Science, the Society for
Marketing Advances, and Southwestern Marketing Association. He has been retained as a
consultant for numerous companies in a variety of industries, as well as by the U.S.
Department of Agriculture and the U.S. Department of Interior. He also has provided expert
testimony, most often in the areas of marketing, advertising and promotions,
entrepreneurship, and statistical and economic analysis, and has planned/presented
executive development and management training programs. Finally, he is regularly invited to
deliver keynote presentations on research techniques, business analytics, and marketing
issues for organizations in the U.S., Europe, Asia, Australia, Africa, South America and
selected other countries.
        </a> */}
    
     
    </Container> 
    
    </BaseLayout>
  );
}

export default AbstractSpeakers2;
