/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "App.css";

// prettier-ignore
const theme = createTheme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: '#f44336',
      },
    },
  });

function SubmitButton() {
  return (
    // prettier-ignore
    <Container className="btnSubmitPaper">     
      <Row className="d-flex justify-content-center" md={4}>
      <Col sm>
          <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="row">
              <a href="../sections/elements/submission-guideline">
                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>SUBMIT YOUR PAPER</Button>
              </a>
            </Stack>
          </ThemeProvider>
        </Col>
      <Col sm>
          <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="row">
              <a href="../sections/elements/submission-guideline">
                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>Proceeding Paper Template</Button>
              </a>
            </Stack>
          </ThemeProvider>
        </Col>
      
        <Col sm>
          <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="row">
              <a href="../sections/elements/camera-ready-submission">
                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>CAMERA READY SUBMISSION</Button>
              </a>
            </Stack>
          </ThemeProvider>
        </Col>
        {/* <Col sm>
          <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="row">
              <a href="../sections/elements/submission-guideline">
                <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>COPYRIGHT AGREEMENT</Button>
                  </a>
            </Stack>
          </ThemeProvider>
        </Col> */}
      </Row>
    </Container>
    // <div className="btnSubmitPaper">
    //     <ThemeProvider theme={theme}>
    //         <Stack spacing={2} direction="row">
    //             <a href="../sections/elements/submission-guideline">
    //                 <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>SUBMIT PAPER</Button>
    //             </a>
    //         </Stack>
    //     </ThemeProvider>

    //     <ThemeProvider theme={theme}>
    //         <Stack spacing={2} direction="row">
    //             <a href="../sections/elements/submission-guideline">
    //                 <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>Proceeding Paper Template</Button>
    //             </a>
    //         </Stack>
    //     </ThemeProvider>

    //     <ThemeProvider theme={theme}>
    //         <Stack spacing={2} direction="row">
    //             <a href="../sections/elements/submission-guideline">
    //                 <Button sx={{ color: theme }} variant="contained" endIcon={<SendIcon />}>SUBMIT PAPER</Button>
    //             </a>
    //         </Stack>
    //     </ThemeProvider>
    // </div>
  );
}

export default SubmitButton;
